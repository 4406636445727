import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { CarModel } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      image: {
        width: 60,
      },
    }
  ),
);

interface Props {
  model?: CarModel,
  size?: number;
}

const ModelImage: React.FC<Props> = ({ model, size }) => {
  const classes = useStyles();
  const styles = size ? {
    width: size,
    height: size / 2,
  } : {};

  if (!model) {
    return null;
  }

  return (
    <img style={styles} className={classes.image} src={`${process.env.CDN_BASE_URL_CARS}/${model.imageUrl}`} alt={model.name} />
  );
};

export default ModelImage;