import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  List,
  Container,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link as MuiLink,
  Typography,
  Box,
  Breadcrumbs
} from "@material-ui/core";
import { graphql, Link } from "gatsby";
import { CarMake, CarModel } from "leasemojo-common";
import Layout from '../components/Layout';
import ModelImage from '../components/CarImage/ModelImage';

import ModelGrid from '../components/CarGrid/ModelGrid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(2, 0),
      },
      icon: {
        marginRight: theme.spacing(4),
      }
    }
  ),
);

interface Props {
  data: {
    car: CarMake,
    allModel: {
      nodes: CarModel[]
    }
  }
}

const ListModels: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  return (
    <Layout contentClassName={classes.root} title={`${data.car.name} models`}>
      <Container maxWidth="md">
        <Typography variant="h5" paragraph align="center">{data.car.name}</Typography>
        <Box mb={1} display="flex" justifyContent="center">
          <Breadcrumbs>
            <MuiLink color="inherit" component={Link} to={'/cars'}>Cars</MuiLink>
            <Typography color="textPrimary">{data.car.name} models</Typography>
          </Breadcrumbs>
        </Box>
        <ModelGrid models={data.allModel.nodes} itemUrl={`/cars/${data.car.slug}/{id}`}/>
      </Container>
    </Layout >
  );
};

export default ListModels;


export const query = graphql`
  query($carId: String!) {
    car(id: { eq: $carId }) {
      name
      logoUrl
      slug
    }
    allModel(filter: {carId: {eq: $carId}}, sort: {fields: name, order: ASC}) {
      nodes {
        name
        id
        carId
        imageUrl
        slug
        modelYear
      }
    }
  }
`
