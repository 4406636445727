import React from "react";
import { Link } from "gatsby";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, Grid, Paper, ButtonBase, Box } from "@material-ui/core";
import { CarModel } from "leasemojo-common";

import ModelImage from '../CarImage/ModelImage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      grid: {
        width: '100%',
        margin: 0,
      },
      item: {
        display: 'flex',
        justifyContent: 'center',
      },
      card: {
        overflow: 'hidden',
      },
      button: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 240,
        // height: 162,
        padding: theme.spacing(2, 0),
      }
    }
  ),
);

interface Props {
  models: CarModel[],
  itemUrl: string;
}

const CarGrid: React.FC<Props> = ({ models, itemUrl }) => {
  const classes = useStyles();


  return (
    <Grid container spacing={2} className={classes.grid}>
      {
        models.map((model, index) => (
          <Grid
            item
            key={model.slug}
            xs={12}
            sm={6}
            md={4}
            className={classes.item}
          >
            <Paper elevation={0} className={classes.card}>
              <ButtonBase
                className={classes.button}
                component={Link}
                to={itemUrl.replace('{id}', model.slug)} >
                <ModelImage model={model} size={220} />
                <Box p={1}>
                  <Typography variant="body2">{model.modelYear} {model.name}</Typography>
                </Box>
              </ButtonBase>
            </Paper>
          </Grid>
        ))
      }
    </Grid>
  );
};

export default CarGrid;